import { View } from "@lightningjs/solid";
import { hexColor } from "@lightningjs/solid";
const FirstPageBackground = () => {
  return (
    <View
      src=""
      width={1920}
      height={1080}
      positionAbsolute={0}
      color={
        hexColor("#1A3378")
      }
    />
  );
};
export default FirstPageBackground;