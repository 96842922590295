import {
  createEffect,
  createSignal,
  Show,
  createSelector,
  For,
  onCleanup,
  onMount,
} from "solid-js";
import { View, Text, hexColor } from "@lightningjs/solid";
import { Column, Button, ButtonContainer, Row } from "@lightningjs/solid-ui";
import { useNavigate } from "@solidjs/router";
import axios from "axios";
import FirstPageBackground from "../../components/FirstPageBackground";
import {
  setIsPlaying,
  setPlayListPassword,
  setPlayListUrl,
  setPlayListUsername,
} from "../../state";
import QrCode from "../../components/QRcode";
import styles from "../../styles";
const ITEMS_PER_PAGE = 4; // Number of items to display at a time
const FirstPage = () => {
  const [data, setData] = createSignal([]);
  const [deviceId, setDeviceId] = createSignal("");
  const [newToken, setNewToken] = createSignal("");
  const [currentIndex, setCurrentIndex] = createSignal(0);
  const [currentIndex2, setCurrentIndex2] = createSignal(0);
  const [response, setResponse] = createSignal(null);
  const [focusedIndex, setFocusedIndex] = createSignal(0);
  const [licenceEndDate, setLicenceEndDate] = createSignal(null);
  const [whiteList, setWhiteList] = createSignal(false);
  const [packageName, setPackageName] = createSignal("");

  const API_URL =
    "https://deviceapi.orbixplay.com/app/live-lbgjx/endpoint/getmydata";
  const API_TOKEN =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJiYWFzX2RldmljZV9pZCI6IjAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMCIsImJhYXNfZG9tYWluX2lkIjoiNjU0NDFiZjQ1OWRjYzYyNWI1YjY4ZGJlIiwiZXhwIjoxNzIxNjczNTc0LCJpYXQiOjE3MjE2NzE3NzQsImlzcyI6IjY2OWVhMDVlNmIwMDVhMDkyNjljYWRiNiIsImp0aSI6IjY2OWVhMDVlNmIwMDVhMDkyNjljYWRiZSIsInN0aXRjaF9kZXZJZCI6IjAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMCIsInN0aXRjaF9kb21haW5JZCI6IjY1NDQxYmY0NTlkY2M2MjViNWI2OGRiZSIsInN1YiI6IjY2NjMwZDYwMDdhNTE4OGU0OGQ4OTgyZCIsInR5cCI6ImFjY2VzcyIsInVzZXJfZGF0YSI6eyJfaWQiOiI2NjYzMGQ2MDA3YTUxODhlNDhkODk4MmUiLCJtb25nb191c2VyX2lkIjoiNjY2MzBkNjAwN2E1MTg4ZTQ4ZDg5ODJkIiwiZGV2aWNlaWQiOiI4QToyQzoxNjpGMTpBODpBMCIsImNyZWF0ZWRfYXQiOnsiJGRhdGUiOnsiJG51bWJlckxvbmciOiIxNzQ5MzAzNDgwMDAwIn19LCJwbGF5bGlzdHMiOltdLCJsaWNlbnNlX2VuZF9kYXRlIjp7IiRkYXRlIjp7IiRudW1iZXJMb25nIjoiMTcxODg3NjEwMDAwMCJ9fSwidnBuX2xpY2Vuc2VfZW5kX2RhdGUiOnsiJGRhdGUiOnsiJG51bWJlckxvbmciOiIxNzUwNDEyMTAwMDAwIn19LCJwYWNrYWdlX25hbWUiOiJQcmVtaXVtIiwiaWQiOiI2NjYzMGQ2MDA3YTUxODhlNDhkODk4MmUiLCJuYW1lIjoiUHJlbWl1bSJ9fQ.WS9l2-So3VTbnYIHP9vS8pspae4n-5DTgrYz0KqUoRA";
  const navigate = useNavigate();

  // console.log("DUID: " + webapis.productinfo.getDuid());

  const parseISODate = (isoDateString) => {
    return isoDateString ? new Date(isoDateString) : null;
  };

  createEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://logindeviceapi.orbixplay.com",
          {
            deviceid: webapis.productinfo.getDuid(),
            orbixplay_id: "8A:2C:16:F1:A8:A0",
            device_mac_address: "",
            app_name: "8A:2C:16:F1:A8:A0",
            app_version: 2,
            ntype: 1,
            hdmi: true,
            firmware: "DeviceInfo.getApplicationName()",
            device_brand: "DeviceInfo.getBrand()",
            screensize: "",
            api_version: "DeviceInfo.getApiLevel()",
            os: "Platform.OS",
            appid: "APPID.id",
          }
        );
        console.log("helloooooooooooooo", response.data);
        setResponse(response.data);
        // Handle the response data if needed
      } catch (error) {
        console.error("Failed to fetch:", error);
        // You can add more error handling logic here if needed
      }
    };
    fetchData();
  }, []);

  createEffect(() => {
    const tedhenat = response();

    if (tedhenat) {
      console.log("keto jane ato", tedhenat);

      //@ts-ignore
      setNewToken(tedhenat.access_token);
    }
  });

  createEffect(() => {
    const fetchData = async () => {
      const tedhenat = response();
      try {
        const response = await axios.get(API_URL, {
          headers: {
            Authorization: `Bearer ${tedhenat.access_token}`,
          },
        });
        setData(response.data.playlists);
        setDeviceId(response.data.deviceid);
        console.log("responset qe dua", response.data);
        setPackageName(response?.data?.package_name);

        const licenceEndDateValue = parseISODate(
          response.data.license_end_date
        );
        const vpnLicenceEndDateValue = parseISODate(
          response.data.vpn_license_end_date
        );

        // Determine the latest end date
        const latestEndDate =
          licenceEndDateValue > vpnLicenceEndDateValue
            ? licenceEndDateValue
            : vpnLicenceEndDateValue;

        setLicenceEndDate(latestEndDate);
        console.log("latest end ", latestEndDate);

        setWhiteList(response.data.whitelist || false);

        // console.log(response.data);
      } catch (error) {
        console.error("Error fetching data1:", error);
      }
    };
    fetchData();
  }, [currentIndex2()]);

  const isFirst = createSelector(() => {
    return 0;
  });
  createEffect(() => {
    setIsPlaying(false);
  });
  const onCustomEnter = () => {
    navigate("home");
    setIsPlaying(true);
  };
  const onDown = () => {
    if (focusedIndex() < ITEMS_PER_PAGE - 1) {
      setFocusedIndex(focusedIndex() + 1);
    } else if (currentIndex() + ITEMS_PER_PAGE < data().length) {
      setCurrentIndex(currentIndex() + 1);
    } else {
      refreshRef.setFocus();
    }
  };
  const onUp = () => {
    if (focusedIndex() > 0) {
      setFocusedIndex(focusedIndex() - 1);
    } else if (currentIndex() > 0) {
      setCurrentIndex(currentIndex() - 1);
    }
  };
  const onRight = () => {
    deleteColumnRef.children[focusedIndex()].setFocus();
  };
  const onLeft = () => {
    playlistColumnRef.children[focusedIndex()].setFocus();
  };
  const handleDelete = (index) => {
    const newData = data().filter((_, i) => i !== index);
    setData(newData);
    setCurrentIndex(0);
    setFocusedIndex(0);
  };
  let playlistColumnRef;
  let deleteColumnRef;
  let refreshRef;

  const expiredLink = "https://orbixplay.com/";

  const changeIndex = async (newIndex) => {
    const tedhenat = data();
    if (!whiteList() && isLicenceExpired()) {
      console.log("Licence has expired. Cannot change index.");
      window.open(expiredLink, "_blank");
      return;
    }

    try {
      //@ts-ignore
      const response = await axios.get(
        `${tedhenat[newIndex].url}/player_api.php?username=${tedhenat[newIndex].username}&password=${tedhenat[newIndex].password}`
      );

      const newData = response.data;
      //@ts-ignore
      setPlayListPassword(tedhenat[newIndex].password);
      //@ts-ignore
      setPlayListUrl(tedhenat[newIndex].url);
      //@ts-ignore
      localStorage.setItem("url", tedhenat[newIndex].url);
      //@ts-ignore
      setPlayListUsername(tedhenat[newIndex].username);
      setCurrentIndex2(newIndex);
      navigate("home");
      setIsPlaying(true);
    } catch (error) {
      console.error("Fetching data failed:", error);
    }
  };
  const [playlistStatus, setPlaylistStatus] = createSignal([]);

  const checkPlaylists = async () => {
    const tedhenat = data();
    const statusArray = [];

    for (let i = 0; i < tedhenat.length; i++) {
      try {
        //@ts-ignore
        const response = await axios.get(
          `${tedhenat[i].url}/player_api.php?username=${tedhenat[i].username}&password=${tedhenat[i].password}`
        );

        if (response.status === 200) {
          //@ts-ignore
          statusArray.push(true);
        } else {
          //@ts-ignore
          statusArray.push(false);
        }
      } catch (error) {
        //@ts-ignore
        statusArray.push(false);
      }
    }
    setPlaylistStatus(statusArray);
  };

  createEffect(() => {
    checkPlaylists();
  });

  const isLicenceExpired = () => {
    if (!licenceEndDate()) {
      return true;
    }

    const endDate = new Date(licenceEndDate());
    const currentDate = new Date();

    return endDate < currentDate;
  };

  // {isLicenceExpired() ? 'Available' : 'Not Available'}

  return (
    <View>
      <FirstPageBackground />
      <View
        width={800}
        height={1000}
        margin={20}
        backgroundColor={hexColor("#ffffff44")}
        x={100}
        y={350}
      >
        <Text y={-100} style={{ color: hexColor("#a6a6a6FF") }}>
          PLAYLISTS
        </Text>
        <Show when={data().length > 0}>
          <View>
            <Column
              ref={(el) => {
                playlistColumnRef = el;
              }}
              onDown={onDown}
              onUp={onUp}
              onRight={onRight}
            >
              <For
                each={data().slice(
                  currentIndex(),
                  currentIndex() + ITEMS_PER_PAGE
                )}
              >
                {(playlist, i) => (
                  <Button
                    style={styles.LicenseButton}
                    onEnter={() => {
                      changeIndex(i() + currentIndex());
                    }}
                    autofocus={i() === focusedIndex()}
                    //@ts-ignore
                    key={playlist.id}
                    borderRadius={20}
                  >
                    {/* @ts-ignore */}
                    {playlist.playlistName},{" "}
                    {playlistStatus()[i() + currentIndex()]
                      ? "Available"
                      : "Not Available"}
                  </Button>
                )}
              </For>
            </Column>
            <Row
              ref={(el) => {
                refreshRef = el;
              }}
              onUp={() => playlistColumnRef.setFocus()}
            >
              <Button
                style={styles.LicenseButton}
                y={450}
                borderRadius={20}
                width={400}
              >
                Refresh
              </Button>
            </Row>
            <View
              x={810}
              ref={(el) => {
                deleteColumnRef = el;
              }}
              onLeft={onLeft}
              style={{ display: "flex", flexDirection: "column" }}
              gap={21}
              // onDown={()=> deleteColumnRef.children.setFocus()}
            >
              <For
                each={data().slice(
                  currentIndex(),
                  currentIndex() + ITEMS_PER_PAGE
                )}
              >
                {(playlist, i) => (
                  <Button
                    style={styles.LicenseButton}
                    onEnter={() => handleDelete(i() + currentIndex())}
                    width={100}
                    borderRadius={20}
                    //@ts-ignore
                    key={`${playlist.id}`}
                  >
                    Delete
                  </Button>
                )}
              </For>
            </View>
          </View>
        </Show>
        <View>
          <Text y={580}>License</Text>
          <Text y={620} style={{ fontSize: 20 }}>
            {isLicenceExpired() ? "Expired" : "Not Expired"}, {packageName()}
          </Text>
          <Show when={licenceEndDate()}>
            <Text y={660} style={{ fontSize: 20 }}>
              Expires , {`${licenceEndDate()}`}
            </Text>
          </Show>
        </View>
      </View>
      <View
        width={500}
        height={500}
        margin={20}
        backgroundColor={hexColor("#ffffff44")}
        x={1200}
        y={300}
      >
        <View style={{ display: "flex", justifyContent: "center" }}>
          <Text y={-100} style={{ color: hexColor("#a6a6a6FF") }}>
            SCAN TO ADD PLAYLIST
          </Text>
        </View>
        <Column>
          <Show when={newToken()}>
            <QrCode api_token={newToken()} />
          </Show>
          <Show when={data()}>
            <View
              display="flex"
              flexDirection="column"
              alignItems="center"
              y={520}
              gap={15}
            >
              <Text style={{ color: hexColor("#a6a6a6FF") }}>or visit</Text>
              <Text>
                https://orbixplay.com/mylist and enter this device id:
              </Text>
              <Text style={{ color: hexColor("#FFA500FF") }}>{deviceId()}</Text>
              <Text style={{ fontSize: 18, color: hexColor("#808080FF") }}>
                Version 0.35
              </Text>
            </View>
          </Show>
        </Column>
      </View>
    </View>
  );
};
export default FirstPage;
