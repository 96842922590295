import {
    createEffect,
    createMemo,
    on,
    createSignal,
    Show,
    createSelector,
    For,
} from "solid-js";
import { ElementNode, IntrinsicTextNodeStyleProps, View, activeElement, hexColor , Text} from "@lightningjs/solid";
import { Button, ButtonContainer, Column, Row } from "@lightningjs/solid-ui";
import { useNavigate, useParams } from "@solidjs/router";
import { TileRow, TileRowCategories, TileRowMovie, TileRowRecomandation } from "../../components";
import styles from "../../styles";
import { playListPassword, playListUrl, playListUsername, setGlobalBackground } from "../../state";
import seriesProvider from "../../api/providers/series";
import serieCategoriesProvider from "../../api/providers/categories_series";
import { createInfiniteScroll } from "../../components/pagination";
import ContentBlock from "../../components/ContentBlock";
import { assertTruthy } from "@lightningjs/renderer/utils";
import { debounce } from "@solid-primitives/scheduled";
import axios from 'axios';
import seriesCategories from "../../api/providers/seriesCategories";


const RowStyles: any = {
    display: "flex",
    justifyContent: "flexStart",
    width: 300,
    height: 300,
    color: hexColor("00000000"),
    gap: 26,
    y: 400,
} satisfies IntrinsicTextNodeStyleProps;

const Series = () => {

    const params = useParams();
    const [columnY, setcolumnY] = createSignal(0);
    const [heroContent, setHeroContent] = createSignal({});
    const navigate = useNavigate();
    const [data, setData] = createSignal(null);

    const isFirst = createSelector(() => {
        return 0;
    });

    const providerCategory = createMemo(() => {
        return createInfiniteScroll(
            seriesCategories(params.filter || "all")
        );
    });


    const [number, setNumber] = createSignal(943);

    // Define an event handler to update the number
    const handleNumberChange = (newNumber: any) => {
        setNumber(newNumber);
    };

    const provider = createMemo(() => {
        return createInfiniteScroll(seriesProvider(params.filter || "all", number()));
    });

    const providerSeriesCategories = createMemo(() => {
        return createInfiniteScroll(serieCategoriesProvider(params.filter || "all"));
    });

    const delayedBackgrounds = debounce(
        (img: string) => setGlobalBackground(img),
        400
    );
    const delayedHero = debounce(
        (content: {}) => setHeroContent(content || {}),
        200
    );

    createEffect(
        on(
            activeElement,
            (elm: any) => {
                if (elm.backdrop) {
                    delayedBackgrounds(elm.backdrop);
                }

                if (elm.heroContent) {
                    delayedHero(elm.heroContent);
                }
            },
            { defer: true }
        )
    );

    function onRowFocus(this: any) {

        this.children.selected?.setFocus();
        setcolumnY((this.y || 0) * -1 + 24);
        let numPages = provider().pages().length;

        if (
            numPages === 0 ||
            (this.parent.selected && this.parent.selected >= numPages - 2)
        ) {
            provider().setPage((p) => p + 1);
        }

    }

    function onEnter(this: ElementNode) {
        let entity = this.children.selected;

        assertTruthy(entity && entity.href);
        navigate(entity.href);
        return true;
    }



    let myButton;
    let viewRef;

    return (
        <View onEscape={() => {
            navigate("/home")
        }}>
            <ContentBlock y={180} x={162} content={heroContent()} />
            <View autofocus onDown={() => viewRef.setFocus()}>
                <Row transition={{ x: { duration: 350 } }} ref={myButton} x={160} gap={40} style={RowStyles}>
                    <For each={providerCategory().pages()}>
                        {(categorie, i) => (
                             <ButtonContainer autofocus={isFirst(i())} index={i()} states={"active"}
                             onFocus={() => { handleNumberChange(categorie.categoryID) }}
                             //@ts-ignore
                             style={{    
                                 borderRadius: 7,
                                 width: 385,
                                 height: 85,
                                 scale: 1,
                                 zIndex: 2,
                                 color: 0x304090,
                                 transition: { scale: { duration: 250, easing: "ease-in-out" } },
                                 border: { width: 0, color: 0x00000000 },
                                 focus: { scale: 1.1, color:   "0x467ffa"}}}
                         >
                             <Text style={{fontSize: 28}}>
                                 {categorie.category_name.length > 20
                                     ? `${categorie.category_name.substring(0, 20)}...`
                                     : categorie.category_name}

                             </Text>
                         </ButtonContainer>
                        )}
                    </For>
                </Row>
            </View>
            <View onUp={() => {
                myButton.setFocus()
            }} clipping style={styles.itemsContainer}>
                <Column
                    plinko
                    ref={viewRef}
                    announce="Movies"
                    y={columnY()}
                    scroll="none"
                    style={styles.Column}
                >
                    <For each={provider().pages()}>
                        {(items, i) => 
                         { 
                            console.log('itemsmssmms', items);
                            
                            return (
                            <TileRowRecomandation
                                items={items}
                                width={1620}
                                onFocus={onRowFocus}
                                onEnter={onEnter}
                            />
                        )}}
                    </For>
                </Column>
            </View>
        </View>
    );
};

export default Series;
