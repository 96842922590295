import api from "..";
import { playListPassword, playListUrl, playListUsername } from "../../state";
import {
  convertItemsToTiles,
  chunkArray,
  convertItemsToTilesChannel,
  convertItemsToTilesChannelCategory,
} from "../formatters/ItemFormatter";

let cache = new Map();
const leftoverTiles = new Map();

export default function (filter: string,) {
  return (pageIndex: number): Promise<any> => {

    const username = playListUsername();
    const password = playListPassword();

    const url = `/player_api.php?username=${username}&password=${password}&action=get_live_categories`;

    if (cache.has(url)) {
      return cache.get(url);
    }

    let result = api._get(url).then((channels) => {
      let results = channels;
      let tiles = (
        leftoverTiles.has(filter) ? leftoverTiles.get(filter) : []
      ).concat(convertItemsToTilesChannelCategory(results));      

      let chunks = chunkArray(tiles);
      if (chunks[chunks.length - 1].length < 7) {
        leftoverTiles.set(filter, chunks.pop());
      } else {
        leftoverTiles.delete(filter);
      }
      return tiles;
    });

    console.log('kevke per te pare', result);
    cache.set(url, result);    
    return result;
  };
}
