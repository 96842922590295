import { createEffect, on } from "solid-js";
import { globalStream, globalStreamType } from "./state";

let manifestUri = "http://ilmiodog.ddns.net/series/PIN2_Donato3/Maceraamico4/362902.mp4";

let avPlayer;

function initializeAVPlayer() {
    avPlayer = webapis.avplay; // Initialize avPlayer

    var listener = {
        onbufferingstart: function () {
            console.log("Buffering start.");
        },
        onbufferingprogress: function (percent) {
            console.log("Buffering progress data : " + percent);
        },
        onbufferingcomplete: function () {
            console.log("Buffering complete.");
            avPlayer.play(); // Ensure playback starts as soon as buffering is complete
        },
        oncurrentplaytime: function (currentTime) {
            console.log("Current playtime: " + currentTime);
        },
        onevent: function (eventType, eventData) {
            console.log("Event type: " + eventType + ", data: " + eventData);
        },
        onerror: function (eventType) {
            console.log("Error type: " + eventType);
        },
        onsubtitlechange: function (duration, text, data3, data4) {
            console.log("Subtitle Text: " + text);
        },
        ondrmevent: function (drmEvent, drmData) {
            console.log("DRM callback: " + drmEvent + ", data: " + drmData);
        }
    };

    avPlayer.setListener(listener);
    avPlayer.stop(); // Ensure player is stopped initially
}

document.addEventListener('DOMContentLoaded', function() {

    initializeAVPlayer();

    createEffect(
        on([globalStream, globalStreamType], ([str, type]) => {
            if (type === 'live') {
                console.log('Live stream selected:', str);
                manifestUri = `http://ilmiodog.ddns.net/live/PIN2_Donato3/Maceraamico4/${str}.ts`;
            } else if (type === 'splash') {
                console.log('Non-live stream selected');
                manifestUri = `/public/assets/OrbixPlaytv.mp4`;
            }
            else {
                console.log('Non-live stream selected');
                manifestUri = `http://ilmiodog.ddns.net/${type}/PIN2_Donato3/Maceraamico4/${str}.mp4`;
            }

            avPlayer.stop();
            avPlayer.open(manifestUri);
            avPlayer.setDisplayRect(0, 0, 1920, 1080);
            avPlayer.prepareAsync(() => {
                console.log("Preparation complete for:", manifestUri);
                avPlayer.play();
            }, (error) => {
                console.log("Preparation error:", error);
            });

            console.log("The video source has been updated to:", manifestUri);
        }, { defer: true })
    );

    avPlayer.stop();
    avPlayer.open(manifestUri);
    avPlayer.setDisplayRect(0, 0, 1920, 1080);
    avPlayer.prepareAsync(() => {
        console.log("Initial preparation complete for:", manifestUri);
        avPlayer.play();
    }, (error) => {
        console.log("Initial preparation error:", error);
    });
});


function onErrorEvent(event) {
    onError(event.detail);
}

function onError(error) {
    console.error("Error code", error.code, "object", error);
}

export function playVideo() {
    if (!avPlayer) {
        console.error("AV Player is not initialized.");
        return;
    }
    const video = document.getElementById("av-player");
    video.hidden = false;
    setTimeout(() => video.play(), 50);
    video.focus();
    return video;
}

export function closeVideo() {
    if (!avPlayer) {
        console.error("AV Player is not initialized.");
        return;
    }
    const video = document.getElementById("av-player");
    video.hidden = true;
    avPlayer.pause();
    return video;
}
