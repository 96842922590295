import {
  ElementNode,
  Text,
  View,
  Show,
  hexColor,
  IntrinsicTextNodeStyleProps,
} from "@lightningjs/solid";
import { Column, Button, Row } from "@lightningjs/solid-ui";
import { useParams } from "@solidjs/router";
import {
  createEffect,
  createResource,
  on,
  createSignal,
  createSelector,
  For,
  createMemo,
} from "solid-js";
import { TileRow, TileRowMovie, TileRowRecomandation } from "../components";
import {
  setGlobalBackground,
  setGlobalStream,
  setGlobalStreamType,
  setIsPlaying,
} from "../state";
import ContentBlock from "../components/ContentBlock";
import { useNavigate } from "@solidjs/router";
import styles from "../styles";
import * as provider from "../api/providers/entity";
import { assertTruthy } from "@lightningjs/renderer/utils";
import type { Tile } from "../api/formatters/ItemFormatter";
import { closeVideo } from "../script";
import axios from "axios";
import { createInfiniteScroll } from "../components/pagination";
import moviesProvider from "../api/providers/episodesProvider";
import { playVideo } from "./video";
import episodesProvider from "../api/providers/episodesProvider";

const RowStyles: any = {
  display: "flex",
  justifyContent: "flexStart",
  width: 300,
  height: 300,
  color: hexColor("00000000"),
  gap: 26,
  y: -110,
} satisfies IntrinsicTextNodeStyleProps;

const Entity = () => {
  const params = useParams();

  const navigate = useNavigate();
  const [movieimage, setMovieimage] = createSignal(null);
  const [valueOfStream, setValueOfStream] = createSignal(null);

  const [backdropAlpha, setBackdropAlpha] = createSignal(0);
  const [dataNew, setDataNew] = createSignal(null);
  const [number, setNumber] = createSignal(null);
  const [index, setIndex] = createSignal(0);
  const [columnYNew, setcolumnYNew] = createSignal(0);

  const [data] = createResource(() => ({ ...params }), provider.getInfo);

  const [recommendations] = createResource<any, Tile[]>(
    () => ({ ...params }),
    provider.getRecommendations
  );

  const [seasons] = createResource<any, Tile[]>(
    () => ({ ...params }),
    provider.getSeriesSeasons
  );

  createEffect(() => {
    const image = movieimage();

    if (seasons()) {
      //@ts-ignore
      setNumber(params.id);
    }
  });

  createEffect(() => {
    const currentData = data();

    if (currentData) {
      //@ts-ignore
      setMovieimage(currentData.backgroundImage);
      //@ts-ignore
      setValueOfStream(currentData.idOfStream);
    }
  });

  // createEffect(() => {
  //   const image = movieimage();

  //   if (data()) {
  //     //@ts-ignore
  //     setGlobalBackground(image[0]);
  //   }
  // });

  const columnY = 640;

  const Backdrop = {
    color: hexColor("#000000"),
    alpha: 0,
    width: 1900,
    height: 890,
    x: -160,
    y: columnY,
    borderRadius: 30,
  };

  function onRowFocus(this: ElementNode) {
    this.children.selected?.setFocus();
    columnRef.y = columnY;
    backdropRef.y = columnY;
    backdropRef.alpha = 0;
  }

  function onEnter(this: ElementNode) {
    let entity = this.children.selected;
    assertTruthy(entity && entity.href);
    navigate(entity.href);
    // console.log(credits());
  }

  function onEnterDemo(this: ElementNode) {
    let entity = this.children.selected;
    //@ts-ignore
    const streamOfID = entity._parent.data.id;
    setGlobalStreamType("series");

    navigate(`/player?str=${streamOfID}`);
  }

  function onEscape() {
    closeVideo();
    // // Set focus back to lightning app
    // document.getElementsByTagName("canvas")[0].focus();
    // entityActions.setFocus();
    // setBackdropAlpha(0);
    setTimeout(() => navigate(-1), 100);
    setIsPlaying(true);
  }

  function onEnterTrailer() {
    const streamOfID = valueOfStream();
    setGlobalStreamType("movie");

    if (data()) {
      // setGlobalStreamType('movie');
      //@ts-ignore
      // setGlobalStream(streamOfID);
      navigate(`/player?str=${streamOfID}`);

      // const video = playVideo();
      //@ts-ignore
      // setActiveElement(video);
    }
  }

  let columnRef, backdropRef, entityActions, seriesRow;
  let myButton;

  // Define an event handler to update the number
  const handleNumberChange = (newNumber: any) => {
    const updatet = newNumber();

    setIndex(newNumber);
  };

  const providerOfSeries = createMemo(() => {
    //@ts-ignore
    return createInfiniteScroll(
      episodesProvider(params.filter || "all", number(), index())
    );
  });

  const isFirst = createSelector(() => {
    return 0;
  });

  function DemoTile(props) {
    const Container = {
      borderRadius: 16,
      width: 285,
      height: 278,
      scale: 1,
      zIndex: 2,
      transition: { scale: { duration: 250, easing: "ease-in-out" } },
      border: { width: 0, color: 0x00000000 },
      focus: { scale: 1.1, border: { color: 0xffffff66, width: 8 } },
    };
    const [color, setColor] = createSignal(0xffffffff);
    console.log("pppprrr", props);

    return (
      <View
        {...props}
        src={
          props.info.movie_image
            ? props.info.movie_image
            : "https://ia.media-imdb.com/images/M/MV5BMTIxOTY1NjUyN15BMl5BanBnXkFtZTcwMjMxMDk1MQ@@"
        }
        onFocus={() => setColor(0xffffffff)}
        onBlur={() => setColor(0xffffffff)}
        style={Container}
      >
        <View x={30}>
          {/* <Text y={30} fontSize={84} color={color()}>
            {props.index}
          </Text> */}
          <Text
            y={290}
            fontSize={42}
            width={340}
            height={42}
            contain="both"
            color={color()}
          >
            {props.title}
          </Text>
        </View>
      </View>
    );
  }

  return (
    <Show when={data()} keyed>
      <View
        onLeft={() => {
          return true;
        }}
        x={170}
        //  onUp={() => entityActions.setFocus()}
        // onLeft={() => onCustomEscape()}

        onEscape={onEscape}
      >
        <ContentBlock y={260} content={data().heroContent}></ContentBlock>
        <Row
          ref={entityActions}
          y={500}
          scroll="none"
          height={90}
          width={640}
          gap={40}
          onDown={() => {
            if (params.type === "movie") {
              columnRef.setFocus();
            } else {
              myButton.setFocus();
            }
          }}
          onEnter={onEnterTrailer}
        >
          {params.type === "movie" && (
            <Button width={300} autofocus>
              Play
            </Button>
          )}
        </Row>

        <Show
          when={params.type === "movie"}
          fallback={
            <Column
              ref={columnRef}
              x={0}
              y={columnY}
              style={styles.Column}
              scroll="none"
              zIndex={5}
            >
              <View>
                <Row
                  transition={{ x: { duration: 350 } }}
                  ref={myButton}
                  onDown={() => {
                    seriesRow.setFocus();
                  }}
                  onUp={() => entityActions.setFocus()}
                  gap={40}
                  style={RowStyles}
                >
                  <For each={seasons()}>
                    {(categorie, i) => {
                      return (
                        <Button
                          autofocus={isFirst(i())}
                          index={i()}
                          states={"active"}
                          onFocus={() => {
                            handleNumberChange(i);
                          }}
                        >
                          {categorie}
                        </Button>
                      );
                    }}
                  </For>
                </Row>

                <Column
                  ref={seriesRow}
                  onUp={() => {
                    myButton.setFocus();
                  }}
                  plinko
                  announce="Series"
                  y={columnYNew() + 50}
                  scroll="none"
                  style={styles.Column}
                  x={10}
                >
                  {/* <For each={providerOfSeries()}>
                  {(items, i) => {
                    console.log('items:', items);
                    return (
                      <TileRowMovie
                        items={items}
                        width={1620}
                        onFocus={onRowFocus}
                        onEnter={onEnter}
                      />
                    );
                  }}
                </For> */}
                  <Row style={styles.Row} justifyContent={"flexStart"} gap={40}>
                    <For each={providerOfSeries().pages()}>
                      {(demo, i) => (
                        <DemoTile onEnter={onEnterDemo} index={i()} {...demo} />
                      )}
                    </For>
                  </Row>
                </Column>
              </View>
            </Column>
          }
        >
          <Column
            ref={columnRef}
            x={0}
            y={columnY}
            style={styles.Column}
            scroll="none"
            zIndex={5}
            onUp={() => entityActions.setFocus()}
          >
            <Show when={recommendations()}>
              <Text skipFocus style={styles.RowTitle}>
                Recommendations
              </Text>
              <TileRowRecomandation
                onFocus={onRowFocus}
                onEnter={onEnter}
                items={recommendations()}
                width={1620}
              />
            </Show>
          </Column>
        </Show>

        <View
          ref={backdropRef}
          style={Backdrop}
          transition={{ alpha: true, y: true }}
        />
      </View>
      <View
        alpha={backdropAlpha()}
        color={hexColor("#000000")}
        zIndex={200}
        transition={{ alpha: true }}
      />
    </Show>
  );
};

export default Entity;
