import { createSignal, createEffect, createMemo, onCleanup, Show } from 'solid-js';
import { For } from 'solid-js';
import { Text, View, setActiveElement } from '@lightningjs/solid';
import { ProgressBar } from '@lightningjs/solid-ui';
import channelsProvider from "../api/providers/livetvChannels";
import { useParams, useLocation } from "@solidjs/router";
import { playVideo, closeVideo } from "../../src/script";
import { playListPassword, playListUrl, playListUsername, setGlobalBackground, setGlobalStream, setGlobalStreamType, setIsPlaying } from '../state';
import axios from 'axios';
import { useNavigate } from "@solidjs/router";
import ChannelMenu from './livetv/ChannelMenu';



const LiveTvPage = () => {
    const navigate = useNavigate();
    const [osdView, setOsdView] = createSignal(true);
    const [channelMenu, setChannelMenu] = createSignal(false);
    const [channelItems, setChannelItems] = createSignal<any[]>([]);
    const [currentIndex, setCurrentIndex] = createSignal(0);
    const [currentStream, setCurrentStream] = createSignal();
    const [data, setData] = createSignal(null);
    const [epgData, setEpgData] = createSignal(null);
    const [screenWidth, setScreenWidth] = createSignal(window.innerWidth);


    const location = useLocation();
    function getQueryParams(search) {
        return new URLSearchParams(search);
    }


    createEffect(() => {
        const params = getQueryParams(location.search);
        const num = parseInt(params.get('num'), 10); // Convert to a number
        setChannelMenu(false);
        osdFocus.setFocus();
        setOsdView(true);
        const tedhenatProve = data();

        if (num && tedhenatProve) {
            const result = tedhenatProve.find(stream => stream.stream_id === num);
            console.log('teDhenat', tedhenatProve);

            if (result) {
                console.log('Stream found:', result);
                const updatetNumber = result.num;
                //@ts-ignore 
                setCurrentIndex(updatetNumber - 1);
            } else {
                console.log('Stream not found');
            }

        }
    });


    const params = useParams();
    const channelProvider = createMemo(() => channelsProvider(params.filter || "all"));

    createEffect(async () => {
        try {
            const dataProvider = channelProvider();
            if (typeof dataProvider === 'function') {
                const data = await dataProvider(0);
                setChannelItems(data);
            }
        } catch (error) {
            console.error("Error fetching data from channelProvider:", error);
        }
    });

    // osd timeout
    let timeoutId;

    // Automatically hide OSD after 5 seconds if it's currently shown
    createEffect(() => {
        if (osdView()) {
            // Clear previous timeout (if any) before setting a new one
            clearTimeout(timeoutId);

            timeoutId = setTimeout(() => {
                setOsdView(false);
            }, 5000);
        }
    });

    // Cleanup the timeout if the effect is re-run before the timeout completes
    onCleanup(() => clearTimeout(timeoutId));

    const playThisChannel = () => {
        if (osdView()) {
            console.log("play stream url = ", channelItems()[currentIndex()].stream_id);
            const video = playVideo();
            //@ts-ignore
            setActiveElement(video);
            //setBackdropAlpha(0.9);
        }
        else {
            //setOsdView(!osdView());
        }

        setOsdView(!osdView());

    };

    const onCustomLeft = () => {
        return true;
    };

    // Function to update index and show OSD
    const updateIndex = (delta: number) => {
        const newIndex = currentIndex() + delta;
        const tedhenat = data();
        const numri = currentIndex();

        if (newIndex >= 0 && newIndex < channelItems().length) {
            setCurrentIndex(newIndex);
            //@ts-ignore
            setCurrentStream(tedhenat[numri].stream_id);
            //@ts-ignore
            setGlobalStream(tedhenat[numri].stream_id);
        }
        // Always set OSD to show when index is updated
        setOsdView(true);
        // Clear previous timeout (if any) before setting a new one
        clearTimeout(timeoutId);
        // Set a new timeout to hide the OSD after 5 seconds
        timeoutId = setTimeout(() => {
            setOsdView(false);
        }, 5000);
    };

    createEffect(() => {
        let streamId = currentStream();
        const tedhenat = data();
        const numri = currentIndex();
        setEpgData(null)
        console.log('numri kur kalon prap', numri);

        const username = playListUsername();
        const password = playListPassword();
        const playList = playListUrl();



        if (tedhenat !== null && numri !== null) {
            //@ts-ignore
            setGlobalStream(tedhenat[numri].stream_id);
            // Make the API call with the streamId using axios
            //@ts-ignore
            axios.get(`${playList}/player_api.php?username=${username}&password=${password}&action=get_short_epg&stream_id=${tedhenat[numri].stream_id}`)
                .then(response => {
                    console.log('API response:', response.data);
                    setEpgData(response.data);
                })
                .catch(error => {
                    console.error('API call failed:', error);
                    setEpgData(null)
                });
        } else {
            setEpgData(null); // Set to empty string if tedhenat or numri is null
        }
    });


    createEffect(() => {
        setIsPlaying(false)

        const timeoutId = setTimeout(() => {
            const videoElement = document.getElementById('video');
            const numri = currentStream();
            setGlobalStreamType('live');
            //@ts-ignore
            setGlobalStream(numri);
            console.log('numri', numri);



            if (videoElement) {
                //@ts-ignore
                const video = playVideo(videoElement);
                // @ts-ignore
                setActiveElement(video);


                console.log('Video started, global stream and type set');
            } else {
                console.error('Video element not found');
            }
        }, 100); // Adjust the delay as necessary

        // Clean up on effect re-run
        onCleanup(() => {
            clearTimeout(timeoutId);
        });
    });


    createEffect(() => {
        const username = playListUsername();
        const password = playListPassword();
        const playList = playListUrl();

        axios.get(`${playList}/player_api.php?username=${username}&password=${password}&action=get_live_streams`)
            .then(response => setData(response.data))
            .catch(error => console.error(error));
    });

    function onCustomEscape() {
        console.log('escape');

        setTimeout(() => {
            setGlobalBackground('#0A1C34');
            navigate("/channel-menu");
        }, 100);

        closeVideo();
        setIsPlaying(true);
    }

    function calculateProgress(startTimeUnix, endTimeUnix) {
        let currentTime = Date.now() / 1000;  // Current time in seconds since Unix epoch
        let startTime = startTimeUnix;
        let endTime = endTimeUnix;

        let totalDuration = endTime - startTime;
        let elapsedDuration = currentTime - startTime;

        // Calculate progress as a value between 0 and 1
        let progress = elapsedDuration / totalDuration;

        // Clamp progress to ensure it's within 0 to 1 range
        progress = Math.min(1, Math.max(0, progress));

        return progress;
    }


    const setChannelToShow = () => {
        //@ts-ignore
        setChannelToShow(true);
    }

    let osdFocus;
    let otherFocus;

    return (
        <View
            onDown={() => updateIndex(-1)}
            onUp={() => updateIndex(1)}
            onHide={() => setOsdView(!osdView())}
            // onEnter={() => playThisChannel()}
            onLeft={() => onCustomLeft()}
            onEscape={() => { onCustomEscape() }}
            onBackspace={() => { onCustomEscape() }}

            onRight={() => { setChannelMenu(true) }}
            autofocus
            ref={osdFocus}
        >
            {channelMenu() &&
                //@ts-ignore
                <ChannelMenu isShowing={false} />
            }
            {osdView() && (
                <>
                    <View
                        //autofocus
                        x={0}
                        y={800}
                        width={screenWidth()}
                        height={200}
                        //@ts-ignore
                        style={{ opacity: 0.1, zIndex: 999, color: "0x304090", focus: { color: "grey" } }}
                    >
                        <Text x={20} y={10}>
                            {channelItems().length > 0 ? `${channelItems()[currentIndex()].num} ${channelItems()[currentIndex()].name}` : "Loading..."}
                        </Text>
                        <View y={30}>
                            {/* @ts-ignore */}
                            <Show when={epgData() && epgData().epg_listings} fallback={<Text y={10} x={20}></Text>}>
                                {data => {
                                    //@ts-ignore
                                    const firstProgram = atob(data()[0]?.title ? data()[0]?.title : "");
                                    //@ts-ignore
                                    const secondProgram = atob(data()[1]?.title ? data()[1]?.title : "");
                                    //@ts-ignore
                                    const startTime = data()[0]?.start_timestamp;
                                    //@ts-ignore
                                    const endTime = data()[0]?.stop_timestamp;

                                    let progress = calculateProgress(startTime, endTime);


                                    let currentDate = new Date();

                                    let dayOfWeek = currentDate.getDay();

                                    let daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

                                    let dayName = daysOfWeek[dayOfWeek];

                                    let hours = currentDate.getHours();
                                    let minutes = currentDate.getMinutes();
                                    let seconds = currentDate.getSeconds();

                                    let formattedHours = hours < 10 ? '0' + hours : hours;
                                    let formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

                                    let timeString = formattedHours + ':' + formattedMinutes;

                                    return (
                                        <View x={20} y={20}>

                                            <Text y={10}>Now : {firstProgram ? firstProgram : `Programs of ${channelItems()[currentIndex()].name}`}</Text>
                                            <Text y={70}>Next : {secondProgram ? secondProgram : `Programs of ${channelItems()[currentIndex()].name}`}</Text>
                                            {/* @ts-ignore */}
                                            <ProgressBar progress={progress} borderRadius={5} height={10} width={1500} y={55} value={0} progressRadius={100} />

                                            <View style={{ justifyContent: 'center', alignItems: 'center' }} x={1600}>
                                                <Text>{dayName}</Text>
                                                <Text y={40}>{timeString}</Text>
                                            </View>
                                        </View>

                                    );
                                }}
                            </Show>
                        </View>
                    </View>
                </>
            )}

        </View>
    );
};

export default LiveTvPage;
