import { useMatch, useNavigate } from "@solidjs/router";
import {
  View,
  Text,
  IntrinsicNodeProps,
  ElementNode,
} from "@lightningjs/solid";
import { Column } from "@lightningjs/solid-ui";
import styles from "./NavDrawer.styles";
import Icon from "../Icon";
import { isPlaying } from "../../state";

interface NavButtonProps extends IntrinsicNodeProps {
  icon: string;
  children: string;
}

function NavButton(props: NavButtonProps) {
  return (
    <View {...props} forwardStates style={styles.NavButton}>
      <View y={-16}>
        <Icon scale={0.5} name={props.icon} />
      </View>
      <Text
        style={{
          fontSize: 38,
          x: 116,
          y: 18,
          height: 50,
          alpha: 0,
          active: {
            alpha: 1,
          },
        }}
      >
        {props.children}
      </Text>
    </View>
  );
}

export default function NavDrawer(props) {
  let backdrop;
  const navigate = useNavigate();
  function onFocus(this: ElementNode) {
    backdrop.states.add("focus");
    this.children.forEach((c) => c.states!.add("active"));
    this.children.selected!.setFocus();
  }

  function onBlur(this: ElementNode) {
    backdrop.states.remove("focus");
    this.selected = 0;
    this.children.forEach((c) => c.states!.remove("active"));
  }

  function handleNavigate(page: string) {

    const isOnPage = useMatch(() => page);
    if (isOnPage()) {
      return props.focusPage();
    }

    navigate(page);

  }

  return (
    <>
      <View
        flexItem={false}
        x={30}
        y={15}
        zIndex={105}
        alpha={props.showWidgets ? 1 : 0}
      >
        <View y={30} x={30} src="./assets/logo.png" width={280} height={85} />
      </View>
      <Column
        {...props}
        onFocus={onFocus}
        onBlur={onBlur}
        style={styles.Column}
        scroll="none"
      >
        {isPlaying() && (
          <>
            {/* <NavButton icon="trending" onEnter={() => handleNavigate("channel-menu")}>
              Home
            </NavButton>
            <NavButton icon="tv" onEnter={() => handleNavigate("/livetv")}>
              Live TV
            </NavButton>
            <NavButton icon="movie" onEnter={() => handleNavigate("movies")}>
              Movies
            </NavButton>
            <NavButton icon="experiment" onEnter={() => handleNavigate("series")}>
              Series
            </NavButton> */}
          </>
        )}
      </Column>
      <View ref={backdrop} style={styles.Gradient}></View>
    </>
  );
}
