import { createEffect, createSignal, onCleanup } from "solid-js";
import { playVideo, closeVideo } from "../script";
import { setActiveElement } from "@lightningjs/solid";
import { setGlobalStream, setGlobalStreamType } from "../state";


const SplashScreen = (props) => {

    createEffect(() => {

        const timeoutId = setTimeout(() => {
            const videoElement = document.getElementById('av-player');

            //@ts-ignore
            setGlobalStreamType('splash');
            setGlobalStream('222')

            if (videoElement) {
                //@ts-ignore
                const video = playVideo(videoElement);
                // @ts-ignore
                setActiveElement(video);


                console.log('Video started, global stream and type set');
            } else {
                console.error('Video element not found');
            }
        }, 10); // Adjust the delay as necessary

        // Clean up on effect re-run
        onCleanup(() => {
            clearTimeout(timeoutId);
        });
    });

    return (
        <>
        </>
    );
};

export default SplashScreen;
