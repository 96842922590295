//@ts-ignore
import { createSignal } from "solid-js";
export const [globalBackground, setGlobalBackground] = createSignal<string>("");
export const [globalStream, setGlobalStream] = createSignal<string>("");
export const [globalStreamType, setGlobalStreamType] = createSignal<string>("");
export const [isPlaying, setIsPlaying] = createSignal<boolean>(true);
export const [playListUrl, setPlayListUrl] = createSignal<string>("");
export const [playListUsername, setPlayListUsername] = createSignal<string>("");
export const [playListPassword, setPlayListPassword] = createSignal<string>("");
export const [isVpnMode, setIsVpnMode] = createSignal<boolean>(false);
